import './index.css'
import React, { useState } from 'react';

const Sfriend = () => {
  const [image, setImage] = useState(require('../../images/ClosedEnvelope.png'));
  const [buttonVisible, setButtonVisible] = useState(true); // State to manage button visibility


  const onClick = () => {
    setImage(require('../../images/envelopeAnimation.gif'));
    setTimeout(() => {
      setButtonVisible(false); // Hide the button after 3 seconds
    }, 5000); // 3000 milliseconds = 3 seconds
  }

  return (
    <div className="Home" >
      <img src={image} width={500} className='image' alt="card" style={{ opacity: buttonVisible ? 1 : 0}} />
      <button
        style={{opacity: buttonVisible ? 1 : 0, zIndex: 100 }}
        class="nes-btn"
        className="nes-btn"
        onClick={() => onClick()}
      >
        Open Card
      </button>
      <p style={{ opacity: buttonVisible ? 0 : 1 }} className='text'>
        Hey Stephanie!
        <br /> <br />
        Thank you so much for giving me an opportunity to work with the DS team this summer. It really has 
        been the greatest and most enjoyable experience that I have had in my short career thus far. The
        team has been so welcoming and supportive that I'm actually sad to stop working now because I'll miss 
        working with the team. I really appreciate all of the freedom that you gave me in being able to take on 
        a project that wasn't even planned for me, and I'm so grateful for all of the guidance that you showed
        along the way. Thank you for allowing me to talk to higher ups and for always giving me the platform and 
        ability to grow and learn. Truly truly, thank you for the amazing opportunity to be able to work with the 
        DS team this summer, I wouldn't trade it for anything.
        <br /> <br />
        Collin
      </p>

    </div>
  )
}

export default Sfriend;

import './index.css'
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Page1 = () => {

  const navigate = useNavigate();

  const onClick = () => {
    navigate('/page2');

  }

  return (
    <div className="Home" style={{ backgroundColor: 'white'}}>
      <img src={require('../../images/image1.png')} style={{ "marginTop": '10vh' }} width={250} className='image' alt="cutePicture" />
      <div className="titleContainer">
        <p style={{fontSize: 20}}>what's goodie jisoohfiemiestermendenquel</p>
      </div>

      <button style={{"fontSize": "1vw", "width": "30vw"}} class="nes-btn" onClick={() => onClick()}>ayy what's good homeslice</button>
    </div>
  )
}

export default Page1;
import './index.css'
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [aDate, setADate] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const onClick = () => {
    if (aDate === "1/15/24" && password === "jisoohf") {
      navigate('/page1');
    }
  }

  return (
    <div className="Home" style={{ backgroundColor: 'white'}}>
      <div className="passwordContainer">
        <p>Enter Your Anniversary Date</p>
      </div>
      <div className="inputContainer">
        <input
          class="nes-input"
          className="input"
          type="text"
          onChange={event => setADate(event.target.value)}
        />
      </div>
      <div className="passwordContainer">
        <p>Password</p>
      </div>
      <div className="inputContainer">
      <input
          class="nes-input"
          className="input"
          type="text"
          onChange={event => setPassword(event.target.value)}
        />
      </div>
      <button class="nes-btn" onClick={() => onClick()}>let's go!</button>
    </div>
  )
}

export default Home;
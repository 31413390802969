import React, { useRef } from 'react';
import Webcam from 'react-webcam';
import backgroundImage from '../../images/background.png'

const Page6 = () => {
  const webcamRef = useRef(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // Do something with the imageSrc, like uploading it to a server
    const downloadLink = document.createElement('a');
    // Set the href attribute to the image data
    downloadLink.href = imageSrc;
    // Set the download attribute to specify the filename
    downloadLink.download = 'webcam_image.jpeg';
    // Simulate a click on the link to trigger the download
    downloadLink.click();
  };

  return (
    <div className="Home" style={{"backgroundImage": `url(${backgroundImage})`, "backgroundRepeat": "repeat", "backgroundSize": 'cover', "backgroundPosition": 'center', "width": "100vw", "height": "100vh"}}>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
      />
      <button class="nes-btn" onClick={capture} style={{marginTop: 10}}>Capture</button>
    </div>
  );
};

export default Page6;

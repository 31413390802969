import './index.css'
import React, { useState } from 'react';

const Kwoodward = () => {
  const [image, setImage] = useState(require('../../images/ClosedEnvelope.png'));
  const [buttonVisible, setButtonVisible] = useState(true); // State to manage button visibility


  const onClick = () => {
    setImage(require('../../images/envelopeAnimation.gif'));
    setTimeout(() => {
      setButtonVisible(false); // Hide the button after 3 seconds
    }, 5000); // 3000 milliseconds = 3 seconds
  }

  return (
    <div className="Home" >
      <img src={image} width={500} className='image' alt="card" style={{ opacity: buttonVisible ? 1 : 0}} />
      <button
        style={{opacity: buttonVisible ? 1 : 0, zIndex: 100 }}
        class="nes-btn"
        className="nes-btn"
        onClick={() => onClick()}
      >
        Open Card
      </button>
      <p style={{ opacity: buttonVisible ? 0 : 1 }} className='text'>
        Hey Karissa!
        <br /> <br />
        Thank you so much for all of your kindness and support throughout my internship. I have to say,
        a huge part of Lintsy's success was due to your guidance/sharing the open source tool with me in our
        1:1. I really appreciated you taking the time to even meet with me, but I also really appreciated all of the knowledge
        and resources that you shared with me. I don't think Lintsy would have ever even gotten off of the ground if not for
        your help. Thank you for always making me feel super supported by hyping up Lintsy or sharing something nice
        someone said. Although it may seem "small", it really meant a lot to me as an engineering intern to feel super supported by my
        design manager. Thank you so much for all of the support and guidance you showed me throughout the internship.
        <br /> <br />
        Collin
      </p>

    </div>
  )
}

export default Kwoodward;

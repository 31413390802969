import 'nes.css/css/nes.min.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/index';
import Page1 from './Pages/Page1';
import Page2 from './Pages/Page2';
import Page3 from './Pages/Page3';
import Page4 from './Pages/Page4';
import Page5 from './Pages/Page5';
import Page6 from './Pages/Page6';
import Emo from './Pages/Emo';
import Jkrull from './Pages/jkrull';
import Jgkelley from './Pages/jgkelley';
import Jbarrett from './Pages/jbarrett';
import Kwaszazak from './Pages/kwaszazak';
import Kwoodward from './Pages/kwoodward';
import Kkelly from './Pages/kkelly';
import Pmontalto from './Pages/pmontalto';
import Johncampbell from './Pages/johncampbell';
import Sfriend from './Pages/sfriend';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/page1' element={<Page1 />} />
          <Route path='/page2' element={<Page2 />} />
          <Route path='/page3' element={<Page3 />} />
          <Route path='/page4' element={<Page4 />} />
          <Route path='/page5' element={<Page5 />} />
          <Route path='/page6' element={<Page6 />} />
          <Route path='/emo' element={<Emo />} />
          <Route path='/jkrull' element={<Jkrull />} />
          <Route path='/jgkelley' element={<Jgkelley />} />
          <Route path='/jbarrett' element={<Jbarrett />} />
          <Route path='/kwaszazak' element={<Kwaszazak />} />
          <Route path='/kwoodward' element={<Kwoodward />} />
          <Route path='/kkelly' element={<Kkelly />} />
          <Route path='/pmontalto' element={<Pmontalto />} />
          <Route path='/johncampbell' element={<Johncampbell />} />
          <Route path='/sfriend' element={<Sfriend />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

import './index.css'
import React, { useState } from 'react';

const Emo = () => {
  const [image, setImage] = useState(require('../../images/ClosedEnvelope.png'));
  const [buttonVisible, setButtonVisible] = useState(true); // State to manage button visibility


  const onClick = () => {
    setImage(require('../../images/envelopeAnimation.gif'));
    setTimeout(() => {
      setButtonVisible(false); // Hide the button after 3 seconds
    }, 5000); // 3000 milliseconds = 3 seconds
  }

  return (
    <div className="Home" >
      <img src={image} width={250} className='image' alt="cutePicture" style={{ opacity: buttonVisible ? 1 : 0 }} />
      <div className="titleContainer">
        <p style={{ fontSize: 20, opacity: buttonVisible ? 1 : 0, transition: 'opacity 1s ease', }}>hi emo</p>
      </div>
      <button
        style={{ "fontSize": "5vw", "width": "30vw", opacity: buttonVisible ? 1 : 0, zIndex: 100 }}
        class="nes-btn"
        className="nes-btn"
        onClick={() => onClick()}
      >
        Open Card
      </button>

      <img src={require('../../images/yorkie.png')} style={{opacity: buttonVisible ? 0 : 1}} className="yorkie" alt="yorkie"/>
      <p style={{ opacity: buttonVisible ? 0 : 1 }} className='text'>
        Happy Birthday Emo! <br /> <br />
        Sorry I didn't have a physical card at the time of your birthday, but I figured I would make you a virtual card instead! 
        To start, I am so so grateful to have you in my life emo. 
        I am sorry that I do not express it well or enough, but at the end of the day I am grateful to have someone who truly 
        looks after me and cares for me despite anything. I appreciate greatly how you love and care for those around you and 
        look up to you a lot in that. I slowly realize more and more how truly blessed I have been to have grown up with not just 
        mom, but also you loving me as a mother. The christ-like love that you have constantly and consistently shown me and noona 
        growing up is something that is easy to take for granted, but I hope that you know that we appreciate you a lot. 
        Thank you for constantly being you emo, I love you. <br />
        Love, Collin
      </p>

    </div>
  )
}

export default Emo;

import './index.css'
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

const Page5 = () => {

  const [clickCounter, setClickCounter] = useState(0);
  const navigate = useNavigate();

  const onClick = () => {
    setClickCounter(clickCounter + 1);
    if (clickCounter > 5) {
      navigate('/page6');
    }
    

  }

  return (
    <div className="Home" style={{ backgroundColor: 'white'}}>
      <img src={require('../../images/image5.jpeg')} style={{ "marginTop": '1vh' }} width={350} className='image' alt="cutePicture"/>
      <div className="titleContainer">
        <p>will you be my valentine</p>

      </div>

      <button style={{"fontSize": "1vw", "width": "30vw"}} class="nes-btn" onClick={() => onClick()}>yes</button>
    </div>
  )
}

export default Page5;
import './index.css'
import React, { useState } from 'react';

const Pmontalto = () => {
  const [image, setImage] = useState(require('../../images/ClosedEnvelope.png'));
  const [buttonVisible, setButtonVisible] = useState(true); // State to manage button visibility


  const onClick = () => {
    setImage(require('../../images/envelopeAnimation.gif'));
    setTimeout(() => {
      setButtonVisible(false); // Hide the button after 3 seconds
    }, 5000); // 3000 milliseconds = 3 seconds
  }

  return (
    <div className="Home" >
      <img src={image} width={500} className='image' alt="card" style={{ opacity: buttonVisible ? 1 : 0}} />
      <button
        style={{opacity: buttonVisible ? 1 : 0, zIndex: 100 }}
        class="nes-btn"
        className="nes-btn"
        onClick={() => onClick()}
      >
        Open Card
      </button>
      <p style={{ opacity: buttonVisible ? 0 : 1 }} className='text'>
        Hey Patrick!
        <br /> <br />
        Thank you so much for always being so quick to welcome and introduce me to iOS development. I appreciated
        all of the resources you gave me in our first 1:1 and being so receptive to the potential of me working with
        you on the iOS team. I really appreciate how quick you were to hop on call with me and help me debug my Swift
        code and answer any questions I had. Thank you so much for always lending a helping hand and being so kind throughout
        the internship!
        <br /> <br />
        Collin
      </p>

    </div>
  )
}

export default Pmontalto;

import './index.css'
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Page3 = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/page4');

  }

  return (
    <div className="Home" style={{ backgroundColor: 'white'}}>
      <img src={require('../../images/image3.jpeg')} style={{ "marginTop": '1vh' }} width={350} className='image' alt="cutePicture"/>
      <div className="titleContainer">
        <p>haha cmon cmon thanks for the compliment, you're too nice 🤭</p>
      </div>

      <button style={{"fontSize": "1vw", "width": "30vw"}} class="nes-btn" onClick={() => onClick()}>no seriously you are the literal G.O.A.T.</button>
    </div>
  )
}

export default Page3;
import './index.css'
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Page2 = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/page3');

  }

  return (
    <div className="Home" style={{ backgroundColor: 'white'}}>
      <img src={require('../../images/image2.png')} style={{ "marginTop": '5vh' }} width={350} className='image' alt="cutePicture"/>
      <div className="titleContainer">
        <p style={{fontSize: 20}}>so I spent some time coding this site up</p>
      </div>

      <button style={{"fontSize": "1vw", "width": "30vw"}} class="nes-btn" onClick={() => onClick()}>ayy you a coding genius frrrrr</button>
    </div>
  )
}

export default Page2;
import './index.css'
import React, { useState } from 'react';

const Jkrull = () => {
  const [image, setImage] = useState(require('../../images/ClosedEnvelope.png'));
  const [buttonVisible, setButtonVisible] = useState(true); // State to manage button visibility


  const onClick = () => {
    setImage(require('../../images/envelopeAnimation.gif'));
    setTimeout(() => {
      setButtonVisible(false); // Hide the button after 3 seconds
    }, 5000); // 3000 milliseconds = 3 seconds
  }

  return (
    <div className="Home" >
      <img src={image} width={500} className='image' alt="card" style={{ opacity: buttonVisible ? 1 : 0}} />
      <button
        style={{opacity: buttonVisible ? 1 : 0, zIndex: 100 }}
        class="nes-btn"
        className="nes-btn"
        onClick={() => onClick()}
      >
        Open Card
      </button>
      <p style={{ opacity: buttonVisible ? 0 : 1 }} className='text'>
        Hey Jasmine!
        <br /> <br />
        Thank you so much for your kindness and support throughout my internship. I truly couldn't have asked
        for a better mentor who was always so patient, down to earth, and willing to help me grow. Thank you so much
        for always offering your time to me whether it was in our weekly 1:1s or on any random Tuesday. Having you as
        a mentor and knowing I could rely on you really made me feel way more comfortable and confident to do/take on work.
        Thank you so much for all of your guidance and advice, I really appreciate it and strongly believe that a huge portion
        of my success during my internship is all thanks to you! I also really enjoyed our ds web eng syncs and just being able to
        chat and hangout, those will be some of my favorite memories from the summer. Thanks for everything Jasmine
        <br /> <br />
        Collin
      </p>

    </div>
  )
}

export default Jkrull;

import './index.css'
import React, { useState } from 'react';

const Jgkelley = () => {
  const [image, setImage] = useState(require('../../images/ClosedEnvelope.png'));
  const [buttonVisible, setButtonVisible] = useState(true); // State to manage button visibility


  const onClick = () => {
    setImage(require('../../images/envelopeAnimation.gif'));
    setTimeout(() => {
      setButtonVisible(false); // Hide the button after 3 seconds
    }, 5000); // 3000 milliseconds = 3 seconds
  }

  return (
    <div className="Home" >
      <img src={image} width={500} className='image' alt="card" style={{ opacity: buttonVisible ? 1 : 0}} />
      <button
        style={{opacity: buttonVisible ? 1 : 0, zIndex: 100 }}
        class="nes-btn"
        className="nes-btn"
        onClick={() => onClick()}
      >
        Open Card
      </button>
      <p style={{ opacity: buttonVisible ? 0 : 1 }} className='text'>
        Hey Gabe!
        <br /> <br />
        Thank you so much for all of your kindness and support throughout my internship. To be completely
        honest, when you first came to the office I was kind of intimidated, but as we got on more calls
        to discuss tokens or figma or design, I realized you chill like that 🤙. Thank you for always being
        willing to answer a random question I had or get on call to discuss something. I really appreciated
        all of your guidance and direction, it gave me the confidence to take on Lintsy and take on conversations with
        higher ups about design. Thanks for everything Gabe, but most importantly thanks for being the Gabe.Of.All.Time.
        <br /> <br />
        Collin
      </p>

    </div>
  )
}

export default Jgkelley;

import './index.css'
import React, { useState } from 'react';

const Kkelly = () => {
  const [image, setImage] = useState(require('../../images/ClosedEnvelope.png'));
  const [buttonVisible, setButtonVisible] = useState(true); // State to manage button visibility


  const onClick = () => {
    setImage(require('../../images/envelopeAnimation.gif'));
    setTimeout(() => {
      setButtonVisible(false); // Hide the button after 3 seconds
    }, 5000); // 3000 milliseconds = 3 seconds
  }

  return (
    <div className="Home" >
      <img src={image} width={500} className='image' alt="card" style={{ opacity: buttonVisible ? 1 : 0}} />
      <button
        style={{opacity: buttonVisible ? 1 : 0, zIndex: 100 }}
        class="nes-btn"
        className="nes-btn"
        onClick={() => onClick()}
      >
        Open Card
      </button>
      <p style={{ opacity: buttonVisible ? 0 : 1 }} className='text'>
        Hey Kate!
        <br /> <br />
        Thank you so much for your kindness and support throughout my internship. One of my greatest
        learnings came from our 1:1, when I realized that the reason Collage functions well in android is 
        because of the empathy and oneness that is present among the android org. Although it sounds kind of
        cliche it made me realize that truly the teamwork and collaboration is the most powerful tool. It was
        super cool to stalk the native design systems channel and see how well oiled the android org and support requests seemed to move. 
        Also, thank you so much for always seemingly being the first one to hype up Lintsy. It really meant a lot to me
        that although we didn't really work together much in my internship, you were always the first one to throw 
        something in chat hyping Lintsy up. It meant a lot to me especially as an intern to feel appreciated and supported.
        Thanks Kate.
        <br /> <br />
        Collin
      </p>

    </div>
  )
}

export default Kkelly;

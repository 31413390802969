import './index.css'
import React, { useState } from 'react';

const Jbarrett = () => {
  const [image, setImage] = useState(require('../../images/ClosedEnvelope.png'));
  const [buttonVisible, setButtonVisible] = useState(true); // State to manage button visibility


  const onClick = () => {
    setImage(require('../../images/envelopeAnimation.gif'));
    setTimeout(() => {
      setButtonVisible(false); // Hide the button after 3 seconds
    }, 5000); // 3000 milliseconds = 3 seconds
  }

  return (
    <div className="Home" >
      <img src={image} width={500} className='image' alt="card" style={{ opacity: buttonVisible ? 1 : 0}} />
      <button
        style={{opacity: buttonVisible ? 1 : 0, zIndex: 100 }}
        class="nes-btn"
        className="nes-btn"
        onClick={() => onClick()}
      >
        Open Card
      </button>
      <p style={{ opacity: buttonVisible ? 0 : 1 }} className='text'>
        Hey Jenny!
        <br /> <br />
        Truth be told, thank you for being the most supportive manager I could have asked for during my internship. 
        One memory I will never forget as a "oh this is what it feels like to have a good manager" is when I presented at the show and tell, and you helped me by adding 
        in the fact that I conducted a bunch of research to get to Lintsy. I really appreciated you stepping in and supporting 
        me like that, it meant a lot. Thank you so so so much for all of the time that you spent with me preparing for the Cross Enablement Demo.
        Not going to lie, I was super nervous about it and kind of regretting signing up for it in the beginning of the week, but 
        being able to walk through it with you and get your expert feedback really made me feel a LOT more confident going into the 
        presentation. I honestly don't feel like I can fully express my gratitude for your kindness and support throughout my internship. 
        I feel like, or actually I know a great portion of my success during my internship would not have been possible without your help.
        Thank you for everything Jenny.
        <br /> <br />
        Collin
      </p>

    </div>
  )
}

export default Jbarrett;

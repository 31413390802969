import './index.css'
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Page4 = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/page5');

  }

  return (
    <div className="Home" style={{ backgroundColor: 'white'}}>
      <img src={require('../../images/image4.gif')} style={{ "marginTop": '1vh' }} width={350} className='image' alt="cutePicture"/>
      <div className="titleContainer">
        <p>no, YOU are the literal goat. And I can't</p>
        <p>go<span style={{"color": "grey"}}>a</span><span style={{"color": "grey"}}>t</span> without you this valentines</p>

      </div>

      <button style={{"fontSize": "1vw", "width": "30vw"}} class="nes-btn" onClick={() => onClick()}>so like... what're you tryna say man</button>
    </div>
  )
}

export default Page4;
import './index.css'
import React, { useState } from 'react';

const Johncampbell = () => {
  const [image, setImage] = useState(require('../../images/ClosedEnvelope.png'));
  const [buttonVisible, setButtonVisible] = useState(true); // State to manage button visibility


  const onClick = () => {
    setImage(require('../../images/envelopeAnimation.gif'));
    setTimeout(() => {
      setButtonVisible(false); // Hide the button after 3 seconds
    }, 5000); // 3000 milliseconds = 3 seconds
  }

  return (
    <div className="Home" >
      <img src={image} width={500} className='image' alt="card" style={{ opacity: buttonVisible ? 1 : 0}} />
      <button
        style={{opacity: buttonVisible ? 1 : 0, zIndex: 100 }}
        class="nes-btn"
        className="nes-btn"
        onClick={() => onClick()}
      >
        Open Card
      </button>
      <p style={{ opacity: buttonVisible ? 0 : 1 }} className='text'>
        Hey Russ!
        <br /> <br />
        Thank you so much for being so kind and supportive throughout my internship. I really appreciate how you were
        always down to hop on a call with me and give me guidance for Lintsy. I felt extremely supported and a lot
        more confident in my project knowing that I had been able to work with you on it. Thank you for always
        lending a helping hand. Thank you as well for your words of encouragement throughout the internship. I really 
        appreiated the kind words you said after I presented in the show and tell. It meant a lot to me. Thanks for everything
        Russ.
        <br /> <br />
        Collin
      </p>

    </div>
  )
}

export default Johncampbell;
